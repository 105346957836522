import React from "react";
import "./App.css";
import Header from "./pages/Header";
import Foot from "./pages/Footer";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./pages/Home";
import { Pre } from "./pages/Pre";
import { PickReal } from "./pages/pick/real";
import { PickPre } from "./pages/pick/pre";

const App = (props: any) => {
  return (
    <>
      {/* <Router> */}
      <Header props={props} />
      <Routes>
        <Route path="/" element={<Navigate to="/real" replace />} />
        <Route path="/real" element={<Home />} />
        <Route path="/pre" element={<Pre />} />
        <Route path="/real/pick" element={<PickReal />} />
        <Route path="/pre/pick" element={<PickPre />} />
      </Routes>
      <Foot />
      {/* </Router> */}
    </>
  );
};

export default App;
