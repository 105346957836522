import React, { useState, useEffect } from "react";
import { API } from "../components/nara-api";
import Table from "react-bootstrap/Table";
import RealData from "../interface/real-data";
import moment from "moment";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";
import StarCheckbox from "../components/starCheckbox";

export const Home = () => {
  const [data, setData] = useState<RealData[]>();
  const [schKey, setSchKey] = useState<string>("");

  const dateFormat = "YYYY-MM-DD";

  const today = dayjs().format("YYYY-MM-DD");
  const yesterday = dayjs().subtract(2, "day").format("YYYY-MM-DD");

  const [isChecked, setIsChecked] = useState(false);

  // const [selectedRange, setSelectedRange] = useState<RangeValue<any>>(null);
  const [selectedRangeString, setSelectedRangeString] = useState<string[]>([yesterday, today]);
  const { RangePicker } = DatePicker;

  const getListData = () => {
    const startDate = dayjs(selectedRangeString[0]).format("YYYYMMDD") + "0000";
    const endDate = dayjs(selectedRangeString[1]).format("YYYYMMDD") + "2359";
    API.get("/es/real-data/list", { params: { schWord: schKey, startDate: startDate, endDate: endDate } })
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onSchKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSchKey(value);
  };

  const onSearchEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const onSearch = () => {
    const startDate = dayjs(selectedRangeString[0]).format("YYYYMMDD") + "0000";
    const endDate = dayjs(selectedRangeString[1]).format("YYYYMMDD") + "2359";
    API.get("/es/real-data/list", { params: { schWord: schKey, startDate: startDate, endDate: endDate } })
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRangePickerChange = (dates: RangeValue<any>, dateStrings: [string, string]) => {
    // The 'dates' argument now correctly has the type 'RangeValue<any>'
    // dateStrings is an array containing the start and end date strings in the specified format
    setSelectedRangeString(dateStrings);
  };

  const handleStarCheckboxChange = (e: any, realSn: number | undefined) => {
    console.log(e, realSn);
    if (data) {
      const newData: RealData[] = data.map((val: RealData, idx: number) => {
        if (val._source.real_sn === realSn) {
          if (e) {
            val._source.isPick = "Y";
          } else {
            val._source.isPick = "N";
          }
        }
        return val;
      });
      setData(newData);

      if (e) {
        API.post("/pg/pick/real", { realSn: realSn })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        API.delete("/pg/pick/real", { data: { realSn: realSn } })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col xs={6}>
            <InputGroup className="mt-3 mb-3 col-3">
              <Form.Control placeholder="나라장터 키워드" aria-label="나라장터 키워드" aria-describedby="basic-addon2" onChange={onSchKeyChange} value={schKey} onKeyDown={onSearchEnter} />
              <Button variant="outline-secondary" id="button-addon2" onClick={onSearch}>
                검색하기
              </Button>
              <RangePicker defaultValue={[dayjs(yesterday, dateFormat), dayjs(today, dateFormat)]} format={dateFormat} onChange={handleRangePickerChange} />
            </InputGroup>
          </Col>
        </Row>
      </Container>

      <Container>
        <Table striped bordered hover style={{ width: "100%" }} className="justify-content-md-center">
          <colgroup>
            <col width="5%" />
            <col width="3%" />
            <col width="*" />
            <col width="10%" />
            <col width="4%" />
            {/* <col width="14%" /> */}
            <col width="14%" />
            {/* <col width="7%" /> */}
            {/* <col width="7%" /> */}
            <col width="12%" />
            <col width="6%" />
            {/* <col width="" /> */}
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">찜</th>
              <th className="text-center">NO</th>
              <th>입찰공고명</th>
              <th className="text-center">입찰공고일자</th>
              <th className="text-center">구분</th>
              {/* <th>공고기관명</th> */}
              <th>수요기관명</th>
              {/* <th>수요기관담당자부서명</th> */}
              {/* <th>수요기관담당자명</th> */}
              <th className="text-center">입찰마감일시</th>
              <th>배정예산금액</th>
              {/* <th>투찰가능업공명</th> */}
            </tr>
          </thead>
          <tbody>
            {data && data?.length > 0 ? (
              data.map((val: RealData, idx: number) => {
                return (
                  <tr key={idx}>
                    <td className="text-center">
                      <StarCheckbox
                        checked={val._source.isPick == "Y" ? true : false}
                        onChange={(e) => handleStarCheckboxChange(e, val._source.real_sn)}
                        labelClassNm={`label-${idx}`}
                        value={val._source.real_sn}
                      />
                    </td>
                    <td className="text-center">{idx + 1}</td>
                    <td>
                      <a href={val._source.bidntceurl} target="_blank" rel="noreferrer">
                        {val._source.bidntcenm}
                      </a>
                    </td>
                    <td className="text-center">{moment.utc(val._source.bidntcedate).format("YYYY-MM-DD")}</td>
                    <td className="text-center">{val._source.bsnsdivnm}</td>
                    {/* <td>{val._source.ntceinsttnm}</td> */}
                    <td>{val._source.dmndinsttnm}</td>
                    {/* <td>{val._source.dmndinsttofcldeptnm}</td> */}
                    {/* <td>{val._source.dmndinsttofclnm}</td> */}
                    <td className="text-center">{val._source.bidclsedate && val._source.bidclsetm ? moment.utc(val._source.bidclsedate).format("YYYY-MM-DD") + " " + val._source.bidclsetm : "-"}</td>
                    <td>{Number(val._source.asignbdgtamt).toLocaleString()}</td>
                    {/* <td>{val._source.bidprcpsblindstrytynm}</td> */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
