import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link as ReactLink } from "react-router-dom";
import Logo from "../assets/logo3.jpeg";

const { Header } = Layout;

const Headers = (props: any) => {
  const [selectedMenu, setSelectedMenu] = useState<string>(() => {
    // Get the selected menu item from localStorage, or set a default value.
    return localStorage.getItem("selectedMenu") || "dashboard";
  });
  const handleMenuClick = (e: any) => {
    const selectedKey = e.key;
    setSelectedMenu(selectedKey);
    updateLocalStorage(selectedKey);
  };

  const updateLocalStorage = (selectedKey: string) => {
    localStorage.setItem("selectedMenu", selectedKey);
  };

  return (
    <div className="App">
      <Layout className="layout">
        <Header>
          <ReactLink to="/">
            <img className="logo" src={Logo} alt="ONS Logo" />
          </ReactLink>
          <Menu theme="dark" mode="horizontal" onClick={handleMenuClick} selectedKeys={[selectedMenu]}>
            <Menu.Item key="home">
              <ReactLink to="/">입찰공고 검색기</ReactLink>
            </Menu.Item>
            <Menu.Item key="real-pick">
              <ReactLink to="/real/pick">입찰공고 찜</ReactLink>
            </Menu.Item>
            <Menu.Item key="pre">
              <ReactLink to="/pre">사전규격 검색기</ReactLink>
            </Menu.Item>
            <Menu.Item key="pre-pick">
              <ReactLink to="/pre/pick">사전규격 찜</ReactLink>
            </Menu.Item>
          </Menu>
        </Header>
      </Layout>
    </div>
  );
};

export default Headers;
