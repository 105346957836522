import React from "react";
import { Layout, theme } from "antd";

const { Footer } = Layout;

function Foot() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div className="App">
      <Layout className="layout">
        <Footer style={{ textAlign: "center" }}>Copyright2023. Open Network System.</Footer>
      </Layout>
    </div>
  );
}

export default Foot;
