import React from "react";

interface StarCheckboxProps {
  checked: boolean;
  labelClassNm: string;
  value?: number;
  onChange: (checked: boolean) => void;
}

const StarCheckbox: React.FC<StarCheckboxProps> = ({ checked, labelClassNm, value, onChange }) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <label className={labelClassNm} style={{}}>
      <input type="checkbox" checked={checked} onChange={handleChange} style={{ display: "none" }} />
      <svg
        // xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={checked ? "gold" : "none"}
        stroke={checked ? "gold" : "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={labelClassNm}
        width="20px"
        height="20px"
      >
        <polygon points="12 2 15.09 9.3 22 10.64 17 16.08 18.18 23 12 19.5 5.82 23 7 16.08 2 10.64 8.91 9.3 12 2" />
      </svg>
    </label>
  );
};

export default StarCheckbox;
