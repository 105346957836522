import React, { useState, useEffect } from "react";
import { API } from "../../components/nara-api";
import Table from "react-bootstrap/Table";
import PickPreData from "../../interface/pre-data-pick";
// import moment from "moment";
import moment from "moment-timezone";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import StarCheckbox from "../../components/starCheckbox";
import { Switch, Tooltip } from "antd";

export const PickPre = () => {
  const [data, setData] = useState<PickPreData[]>();

  const getListData = () => {
    API.get("/pg/pick/pre/list")
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStarCheckboxChange = (e: any, preSn: number | undefined) => {
    console.log(e, preSn);
    if (data) {
      const newData: PickPreData[] = data.map((val: PickPreData, idx: number) => {
        if (val.pre_sn === preSn) {
          if (e) {
            val.useYn = "Y";
          } else {
            val.useYn = "N";
          }
        }
        return val;
      });
      setData(newData);

      if (e) {
        API.post("/pg/pick/pre", { preSn: preSn })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        API.delete("/pg/pick/pre", { data: { preSn: preSn } })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const handleEmailSwitch = (e: any, preSn: number | undefined) => {
    console.log(e, preSn);
    if (data) {
      const newData: PickPreData[] = data.map((val: PickPreData, idx: number) => {
        if (val.pre_sn === preSn) {
          if (e) {
            val.emailYn = "Y";
          } else {
            val.emailYn = "N";
          }
        }
        return val;
      });
      setData(newData);

      if (e) {
        API.post("/pg/pick/pre/email", { type: "create", preSn: preSn })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        API.post("/pg/pick/pre/email", { type: "delete", preSn: preSn })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  useEffect(() => {
    getListData();
  }, []);
  return (
    <>
      <Container>
        <Table striped bordered hover style={{ width: "100%" }} className="justify-content-md-center mt-4">
          <colgroup>
            <col width="5%" />
            <col width="3%" />
            <col width="*" />
            <col width="10%" />
            <col width="4%" />
            <col width="14%" />
            <col width="12%" />
            <col width="6%" />
            <col width="6%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">찜</th>
              <th className="text-center">NO</th>
              <th>사전공고명</th>
              <th className="text-center">사전공고일자</th>
              <th className="text-center">구분</th>
              {/* <th>발주기관명</th> */}
              <th>실수요기관명</th>
              <th className="text-center">의견등록마감일시</th>
              {/* <th className="text-center">납품기한일시</th> */}
              <th>배정예산금액</th>
              <th className="text-center">문서</th>
              <Tooltip placement="rightBottom" title="아침 뉴스레터에 별도로 포함됩니다.">
                <th>이메일</th>
              </Tooltip>
            </tr>
          </thead>
          <tbody>
            {data && data?.length > 0 ? (
              data.map((val: PickPreData, idx: number) => {
                return (
                  <tr key={idx}>
                    <td className="text-center">
                      <StarCheckbox checked={val.useYn === "Y" ? true : false} onChange={(e) => handleStarCheckboxChange(e, val.pre_sn)} labelClassNm={`label-${idx}`} value={val.pre_sn} />
                    </td>
                    <td className="text-center">{idx + 1}</td>
                    <td>{val.prdctclsfcnonm}</td>
                    <td className="text-center">{moment.utc(val.rcptdt).tz("Asia/Seoul").format("YYYY-MM-DD")}</td>
                    <td className="text-center">{val.bsnsdivnm}</td>
                    {/* <td>{val.orderinsttnm}</td> */}
                    <td>{val.rldminsttnm}</td>
                    <td>{moment.utc(val.opninrgstclsedt).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm")}</td>
                    {/* <td>{val.dlvrtmlmtdt ? moment.utc(val.dlvrtmlmtdt).tz("Asia/Seoul").format("YYYY-MM-DD") : "-"}</td> */}
                    {/* <td>{val.dlvrtmlmtdt}</td> */}
                    {/* <td className="text-center">{val.bidclsedate && val.bidclsetm ? moment.utc(val.bidclsedate).format("YYYY-MM-DD") + " " + val.bidclsetm : "-"}</td> */}
                    <td>{Number(val.asignbdgtamt).toLocaleString()}</td>
                    <td>
                      <Button variant={val.specdocfileurl1 ? "warning" : "light"} disabled={val.specdocfileurl1 ? false : true}>
                        <Link to={val.specdocfileurl1 ? val.specdocfileurl1 : ""} target="_blank">
                          다운
                        </Link>
                      </Button>
                    </td>
                    <td>
                      <Switch checked={val.emailYn === "Y" ? true : false} onChange={(e) => handleEmailSwitch(e, val.preSn)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
